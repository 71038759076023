@font-face {
  font-family: 'icomoon';
  src:  url(/_next/static/media/icomoon.17a1f7ad.eot);
  src:  url(/_next/static/media/icomoon.17a1f7ad.eot#iefix) format('embedded-opentype'),
    url(/_next/static/media/icomoon.390f55d9.ttf) format('truetype'),
    url(/_next/static/media/icomoon.51188c00.woff) format('woff'),
    url(/_next/static/media/icomoon.39c6a1c6.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-transfer_v2:before {
  content: "\e930";
}
.icon-member:before {
  content: "\e92e";
}
.icon-investorBroker:before {
  content: "\e92f";
}
.icon-copy:before {
  content: "\e900";
}
.icon-dashboard:before {
  content: "\e901";
}
.icon-date:before {
  content: "\e902";
}
.icon-deposit:before {
  content: "\e903";
}
.icon-dot:before {
  content: "\e904";
}
.icon-download:before {
  content: "\e905";
}
.icon-dropdown:before {
  content: "\e906";
}
.icon-edit:before {
  content: "\e907";
}
.icon-email:before {
  content: "\e908";
}
.icon-eye:before {
  content: "\e909";
}
.icon-eyeClosed:before {
  content: "\e90a";
}
.icon-icon-verified:before {
  content: "\e90b";
}
.icon-invest:before {
  content: "\e90c";
}
.icon-invite:before {
  content: "\e90d";
}
.icon-kyc:before {
  content: "\e90e";
}
.icon-left:before {
  content: "\e90f";
}
.icon-logout:before {
  content: "\e910";
}
.icon-menu:before {
  content: "\e911";
}
.icon-multiLevel:before {
  content: "\e912";
}
.icon-noData:before {
  content: "\e913";
}
.icon-noEmail:before {
  content: "\e914";
}
.icon-notification:before {
  content: "\e915";
}
.icon-password:before {
  content: "\e916";
}
.icon-phone:before {
  content: "\e917";
}
.icon-privacyPolicy:before {
  content: "\e918";
}
.icon-profile:before {
  content: "\e919";
}
.icon-profit:before {
  content: "\e91a";
}
.icon-read:before {
  content: "\e91b";
}
.icon-reminder .path1:before {
  content: "\e91c";
  color: rgb(33, 33, 33);
}
.icon-reminder .path2:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-reminder .path3:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-return:before {
  content: "\e91f";
}
.icon-right:before {
  content: "\e920";
}
.icon-securityCenter:before {
  content: "\e921";
}
.icon-share:before {
  content: "\e922";
}
.icon-team:before {
  content: "\e923";
}
.icon-terms:before {
  content: "\e924";
}
.icon-transfer:before {
  content: "\e925";
}
.icon-upload:before {
  content: "\e926";
}
.icon-usdt:before {
  content: "\e927";
}
.icon-view:before {
  content: "\e928";
}
.icon-wallet:before {
  content: "\e929";
}
.icon-withdraw:before {
  content: "\e92a";
}
.icon-back:before {
  content: "\e92b";
}
.icon-capital:before {
  content: "\e92c";
}
.icon-close:before {
  content: "\e92d";
}

/* cyrillic-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_d65c78';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_d65c78';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: '__Inter_d65c78', '__Inter_Fallback_d65c78';font-style: normal
}.__variable_d65c78 {--font-sans: '__Inter_d65c78', '__Inter_Fallback_d65c78'
}

/* cyrillic-ext */
@font-face {
  font-family: '__Fira_Code_74c79e';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/9f05b6a2725a7318-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Fira_Code_74c79e';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/c740c1d45290834f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Fira_Code_74c79e';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/d0697bdd3fb49a78-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Fira_Code_74c79e';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/9bbb7f84f3601865-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Fira_Code_74c79e';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/a8eac78432f0a60b-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Fira_Code_74c79e';
  font-style: normal;
  font-weight: 300 700;
  font-display: swap;
  src: url(/_next/static/media/122c360d7fe6d395-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Fira_Code_Fallback_74c79e';src: local("Arial");ascent-override: 73.56%;descent-override: 23.92%;line-gap-override: 0.00%;size-adjust: 134.59%
}.__className_74c79e {font-family: '__Fira_Code_74c79e', '__Fira_Code_Fallback_74c79e';font-style: normal
}.__variable_74c79e {--font-mono: '__Fira_Code_74c79e', '__Fira_Code_Fallback_74c79e'
}

