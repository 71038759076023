@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?d7k2sc');
  src:  url('fonts/icomoon.eot?d7k2sc#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?d7k2sc') format('truetype'),
    url('fonts/icomoon.woff?d7k2sc') format('woff'),
    url('fonts/icomoon.svg?d7k2sc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-transfer_v2:before {
  content: "\e930";
}
.icon-member:before {
  content: "\e92e";
}
.icon-investorBroker:before {
  content: "\e92f";
}
.icon-copy:before {
  content: "\e900";
}
.icon-dashboard:before {
  content: "\e901";
}
.icon-date:before {
  content: "\e902";
}
.icon-deposit:before {
  content: "\e903";
}
.icon-dot:before {
  content: "\e904";
}
.icon-download:before {
  content: "\e905";
}
.icon-dropdown:before {
  content: "\e906";
}
.icon-edit:before {
  content: "\e907";
}
.icon-email:before {
  content: "\e908";
}
.icon-eye:before {
  content: "\e909";
}
.icon-eyeClosed:before {
  content: "\e90a";
}
.icon-icon-verified:before {
  content: "\e90b";
}
.icon-invest:before {
  content: "\e90c";
}
.icon-invite:before {
  content: "\e90d";
}
.icon-kyc:before {
  content: "\e90e";
}
.icon-left:before {
  content: "\e90f";
}
.icon-logout:before {
  content: "\e910";
}
.icon-menu:before {
  content: "\e911";
}
.icon-multiLevel:before {
  content: "\e912";
}
.icon-noData:before {
  content: "\e913";
}
.icon-noEmail:before {
  content: "\e914";
}
.icon-notification:before {
  content: "\e915";
}
.icon-password:before {
  content: "\e916";
}
.icon-phone:before {
  content: "\e917";
}
.icon-privacyPolicy:before {
  content: "\e918";
}
.icon-profile:before {
  content: "\e919";
}
.icon-profit:before {
  content: "\e91a";
}
.icon-read:before {
  content: "\e91b";
}
.icon-reminder .path1:before {
  content: "\e91c";
  color: rgb(33, 33, 33);
}
.icon-reminder .path2:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-reminder .path3:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-return:before {
  content: "\e91f";
}
.icon-right:before {
  content: "\e920";
}
.icon-securityCenter:before {
  content: "\e921";
}
.icon-share:before {
  content: "\e922";
}
.icon-team:before {
  content: "\e923";
}
.icon-terms:before {
  content: "\e924";
}
.icon-transfer:before {
  content: "\e925";
}
.icon-upload:before {
  content: "\e926";
}
.icon-usdt:before {
  content: "\e927";
}
.icon-view:before {
  content: "\e928";
}
.icon-wallet:before {
  content: "\e929";
}
.icon-withdraw:before {
  content: "\e92a";
}
.icon-back:before {
  content: "\e92b";
}
.icon-capital:before {
  content: "\e92c";
}
.icon-close:before {
  content: "\e92d";
}
